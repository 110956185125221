import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { Modal, Table, Button, Col, Row, Input, Tooltip, Form, Space } from 'antd';
import { notification } from 'antd';

import {
    LoadingOutlined,
    PlusOutlined,
    DeleteOutlined,
    CheckOutlined,
    PrinterOutlined,
    ReloadOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import BarcodePdf from '@print-forms/barcode-pdf';

import { exception, noAccessError } from '@extensions/notification';
import { userLoaded } from '@store/actions';

import { Permission, hasPermission } from '@enums/permission';
import { WarehouseType } from '@enums/warehouse-type';

import { serverFetch } from '@src/core/server';

const Consignment = () => {
    const { TextArea } = Input;

    const { truckId, markingCode, consignmentId } = useParams();

    const navigate = useNavigate();
    const d = useDispatch();
    const userSession = useSelector((s) => s.userSession);
    const warehouseType = useSelector((s) => s.warehouseType);

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const [entity, setEntity] = useState({});
    const [loading, setLoading] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState(true);
    const [allBoxesAccepted, setAllBoxesAccepted] = useState(false);

    const [printData, setPrintData] = useState();
    const [startPrint, setStartPrint] = useState(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        if (!warehouseType || warehouseType === WarehouseType.Main) {
            serverFetch(`consignments/${consignmentId}`, {
                method: 'GET',
                queryParams: { truckId: truckId, markingCode: markingCode },
            })
                .then((data) => {
                    setEntity(data);

                    let allAccepted = true;

                    data.boxGroups.map((g) => {
                        g.boxes.map((b) => {
                            if (!b.acceptedOn) {
                                allAccepted = false;
                                return;
                            }
                        });

                        if (!allAccepted) return;
                    });

                    setAllBoxesAccepted(allAccepted);
                })
                .catch((ex) => {
                    exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
                });
        } else {
            serverFetch(`transitwarehouse/acceptance/consignment`, {
                method: 'GET',
                queryParams: { truckIds: [truckId], markingCode: markingCode },
            })
                .then((data) => {
                    setEntity(data);

                    let allAccepted = true;

                    data.boxGroups.map((g) => {
                        g.boxes.map((b) => {
                            if (!b.acceptedOn) {
                                allAccepted = false;
                                return;
                            }
                        });

                        if (!allAccepted) return;
                    });

                    setAllBoxesAccepted(allAccepted);
                })
                .catch((ex) => {
                    exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
                });
        }
    }, [refreshRequired]);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData();

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const getPrintData = (ids) => {
        serverFetch(`warehouse/box/print`, { method: 'POST', bodyData: ids })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(null)));
            });
    };

    const onAdd = (boxGroupId) => {
        setLoading(true);

        serverFetch(`warehouse/box/${boxGroupId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка добавления коробки', ex, () => d(userLoaded(null)));
            });
    };

    const onDeleteBox = (boxId) => {
        setLoading(true);

        serverFetch(`warehouse/box/${boxId}`, { method: 'DELETE' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления коробки', ex, () => d(userLoaded(null)));
            });
    };

    const onAcceptBox = (boxId) => {
        setLoading(true);

        serverFetch(`warehouse/acceptbox/${boxId}/${entity.truckId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка приемки коробки', ex, () => d(userLoaded(null)));
            });
    };

    const onCompleteConsignment = () => {
        setLoading(true);

        serverFetch(`warehouse/${consignmentId}/completeconsignment`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка завершения партии', ex, () => d(userLoaded(null)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
            />
        );
    };

    const expandedBoxes = (record) => {
        const columns = [
            {
                title: 'Номер',
                dataIndex: 'number',
            },
            {
                title: 'Принят',
                dataIndex: 'number',
                width: 100,
                align: 'center',
                render: (_, record) => {
                    return record.acceptedOn && <CheckOutlined />;
                },
            },
            {
                title: ' ',
                key: 'boxAction',
                align: 'center',
                width: 80,
                render: (_, record) => {
                    return (
                        <Space>
                            {hasPermission(userSession.permissions, Permission.AcceptBox) && (
                                <Tooltip placement='topRight' title='Принять коробку на баланс'>
                                    <Button
                                        type={!record.acceptedOn ? 'primary' : 'default'}
                                        disabled={record.acceptedOn}
                                        icon={<CheckOutlined />}
                                        size={'small'}
                                        onClick={() => {
                                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                                noAccessError(api, [Permission.ManageWarehouse]);
                                                return;
                                            }

                                            modal.confirm({
                                                title: `Принять коробку "${record.number}" на баланс?`,
                                                okType: 'primary',
                                                icon: <QuestionCircleOutlined />,
                                                okText: 'ОК',
                                                cancelText: 'Отмена',
                                                onOk: () => {
                                                    onAcceptBox(record.id);
                                                },
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <Tooltip placement='topRight' title='Удалить коробку'>
                                <Button
                                    disabled={record.acceptedOn}
                                    icon={<DeleteOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.DeleteWarehouseBox)) {
                                            noAccessError(api, [Permission.DeleteWarehouseBox]);
                                            return;
                                        }

                                        modal.confirm({
                                            title: `Удалить коробку "${record.number}"?`,
                                            okType: 'primary',
                                            icon: <QuestionCircleOutlined />,
                                            okText: 'Удалить',
                                            cancelText: 'Отмена',
                                            onOk: () => {
                                                onDeleteBox(record.id);
                                            },
                                        });
                                    }}
                                />
                            </Tooltip>

                            <Tooltip placement='topRight' title='Печатать штрих-коды'>
                                <Button
                                    icon={<PrinterOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                            noAccessError(api, [Permission.ManageWarehouse]);
                                            return;
                                        }

                                        getPrintData([record.id]);
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    );
                },
            },
        ];

        return <Table rowKey='id' size='small' columns={columns} dataSource={record.boxes} pagination={false} />;
    };

    const renderTable = () => {
        const columns = [
            {
                title: 'Страна',
                dataIndex: 'countryName',
                key: 'countryName',
            },
            {
                key: 'groupAction',
                width: 55,
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <Tooltip placement='topRight' title='Добавить коробку'>
                                <Button
                                    type='primary'
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                            noAccessError(api, [Permission.ManageWarehouse]);
                                            return;
                                        }

                                        modal.confirm({
                                            title: `Добавить коробку?`,
                                            okType: 'primary',
                                            icon: <QuestionCircleOutlined />,
                                            okText: 'Добавить',
                                            cancelText: 'Отмена',
                                            onOk: () => {
                                                if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                                    noAccessError(api, [Permission.ManageWarehouse]);
                                                    return;
                                                }

                                                onAdd(record.id);
                                            },
                                        });
                                    }}
                                />
                            </Tooltip>
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ fontWeight: 600, color: record.scannedBoxQty === record.actualBoxQty ? '#228B22' : 'red' }}>
                                {record.scannedBoxQty}
                            </span>
                            <span> / </span>
                            <span>{record.actualBoxQty}</span>
                        </>
                    );
                },
            },
        ];

        return (
            entity.boxGroups && (
                <Table
                    showHeader={false}
                    rowKey='id'
                    size='small'
                    loading={{
                        spinning: loading,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    columns={columns}
                    expandable={{ expandedRowRender: expandedBoxes, defaultExpandedRowKeys: entity?.boxGroups?.map((b) => b.id) }}
                    dataSource={entity.boxGroups || []}
                    pagination={false}
                    scroll={{ y: `calc(100vh - 150px)` }}
                />
            )
        );
    };

    return (
        <>
            <Row>
                <Col span={9}>
                    <Row>
                        <FormHeader title={`Детали партии`} />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Form.Item label='ID'>
                                    <Input disabled value={entity.consigneeCode} />
                                </Form.Item>
                                <Form.Item label='Маркировка'>
                                    <Input disabled value={entity.markingCode} />
                                </Form.Item>
                                <Form.Item label='Комментарий'>
                                    <TextArea disabled rows={4} value={entity.comment} />
                                </Form.Item>
                                {hasPermission(userSession.permissions, Permission.FullAccess) && (
                                    <Form.Item wrapperCol={{ offset: 11 }}>
                                        <Space size={'small'} style={{ float: 'right' }}>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                onClick={() => {
                                                    if (!allBoxesAccepted) {
                                                        modal.confirm({
                                                            title: `Не все коробки обработаны. Хотите завершить приемку?`,
                                                            okType: 'primary',
                                                            icon: <QuestionCircleOutlined />,
                                                            okText: 'Завершить',
                                                            cancelText: 'Отмена',
                                                            onOk: () => {
                                                                onCompleteConsignment();
                                                            },
                                                        });
                                                    } else {
                                                        onCompleteConsignment();
                                                    }
                                                }}
                                            >
                                                Завершить
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={14}>
                    <div style={{ marginTop: 10 }}>
                        {renderToolbar()}
                        {!loading && renderTable()}
                    </div>
                </Col>
                {contextHolder}
                {modalContextHolder}
            </Row>

            {startPrint && <PrintProcessing />}
        </>
    );
};

export default Consignment;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, InputNumber, Input, Select, Form, Space, Flex, notification, Switch } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { getEnumList } from '@extensions/utils';
import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ICountry } from '@entities/country';

import { Permission, hasPermission } from '@enums/permission';
import { UnitType, enumLabel as unitTypeLabel } from '@enums/unit-type';
import { IEnumItem } from '@enums/enum-item';

const Country = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [unitTypes] = useState<Array<IEnumItem>>(getEnumList(UnitType, unitTypeLabel));

    const [entity, setEntity] = useState<ICountry>({
        id: undefined,
        name: undefined,
        code: undefined,
        sequence: undefined,
        generateTariff: false,
        unitType: undefined,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageCountries));

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`countries/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onFinishSave = () => {
        setLoading(true);

        serverFetch(`countries`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения страны', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (isViewOnly ? 'Информация о стране' : 'Изменить страну') : 'Добавить страну'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 6 }} onFinish={onFinishSave}>
                    <Form.Item
                        initialValue={entity?.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название страны' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.code}
                        required
                        label='Код'
                        name='code'
                        rules={[{ required: true, message: 'Укажите код страны' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, code: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.unitType}
                        required
                        label='Единица товара'
                        name='unitType'
                        rules={[{ required: true, message: 'Выберите единицу товара' }]}
                    >
                        <Select
                            showSearch
                            onChange={(value) => setEntity({ ...entity, unitType: value })}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={unitTypes.map((c) => {
                                return { value: c.value, label: c.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.sequence}
                        label='Порядковый номер'
                        name='sequence'
                        required
                        rules={[{ required: true, message: 'Укажите порядковый номер' }]}
                    >
                        <InputNumber
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, sequence: value as number });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Генерирация тарифа'
                        initialValue={entity?.generateTariff}
                        valuePropName='checked'
                        name='generateTariff'
                    >
                        <Switch
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, generateTariff: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 4, span: 6 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {!isViewOnly && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Country;

import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Modal, Table, notification, Row, Col, Divider } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    CheckOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Cargos from '@components/countries/cargos';

import { exception, noAccessError } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ICountry } from '@entities/country';

import { enumLabel as unitTypeLabel } from '@enums/unit-type';

const Countries = () => {
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [selectedCountryIds, setSelectedCountryIds] = useState<React.Key[]>([]);
    const [currentCountry, setCurrentCountry] = useState<ICountry>();
    const [refreshRequired, setRefreshRequired] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    const init = () => {
        setCountries([]);

        setLoading(true);

        serverFetch('countries', { method: 'GET' })
            .then((data) => {
                setCountries(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onDelete = () => {
        if (!currentCountry) return;

        serverFetch(`countries/${currentCountry.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления стран', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderCountryToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageCountries)) {
                                noAccessError(api, [Permission.ManageCountries]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${hasPermission(userSession.permissions, Permission.ManageCountries) ? 'Изменить' : 'Информация о стране'}`,
                        key: 'edit',
                        disabled: !currentCountry,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentCountry?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentCountry,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteCountry)) {
                                noAccessError(api, [Permission.DeleteCountry]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить страну "${currentCountry?.name}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderCargoToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageCountries)) {
                                noAccessError(api, [Permission.ManageCountries]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${hasPermission(userSession.permissions, Permission.ManageCountries) ? 'Изменить' : 'Информация о стране'}`,
                        key: 'edit',
                        disabled: !currentCountry,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentCountry?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentCountry,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteCountry)) {
                                noAccessError(api, [Permission.DeleteCountry]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить страну "${currentCountry?.name}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCountryIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = countries.find((o) => o.id == selectedRowKeys[0]);
            setCurrentCountry(entity);
        } else {
            setCurrentCountry(undefined);
        }
    };

    const renderCountriesTable = () => {
        const columns: ColumnsType<ICountry> = [
            {
                title: '#',
                dataIndex: 'sequence',
                align: 'center',
                width: 60,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                width: 160,
            },
            {
                title: 'Код',
                dataIndex: 'code',
                width: 80,
            },
            {
                title: 'Единица товара',
                align: 'center',
                width: 130,
                render: (_: any, record) => {
                    return unitTypeLabel(record.unitType);
                },
            },
            {
                title: 'Генерирация тарифа',
                align: 'center',
                width: 180,
                render: (_: any, record) => {
                    return record.generateTariff ? <CheckOutlined /> : '';
                },
            },
            {},
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedCountryIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={countries}
                loading={tableLoading}
                onRow={(record: ICountry) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <Row>
            <Col span={10}>
                {renderCountryToolbar()}
                {renderCountriesTable()}
                {contextHolder}
            </Col>
            <Col offset={1} span={13}>
                <Divider orientation='left'>Перевозчики</Divider>
                <Cargos countryId={currentCountry?.id} />
            </Col>
        </Row>
    );
};

export default Countries;

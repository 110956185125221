import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, Select, Form, Space, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { getEnumList } from '@extensions/utils';
import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ICargo } from '@entities/cargo';

import { Permission, hasPermission } from '@enums/permission';
import { IEnumItem } from '@enums/enum-item';

import { TruckIcon, AirplaneIcon } from '@icons/index';

const Country = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<ICargo>({
        id: undefined,
        countryId: undefined,
        name: undefined,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageCountries));

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`cargoes/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевозчика', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onFinishSave = (data: ICargo) => {
        setLoading(true);

        serverFetch(`cargoes`, { method: id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения перевозчика', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (isViewOnly ? 'Информация о перевозчике' : 'Изменить перевозчика') : 'Добавить перевозчика'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} onFinish={onFinishSave}>
                    <Form.Item name='id' initialValue={entity?.id} hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название перевозчика' }]}
                    >
                        <Input disabled={isViewOnly} autoFocus />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {!isViewOnly && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Country;

import { IUserSession } from '@entities/user-session';

export const USER_LOADED: string = 'USER_LOADED';
export const CURRENT_MENY_ITEM: string = 'CURRENT_MENY_ITEM';
export const SET_RESTART_SERVICE_REQUIRED: string = 'SET_RESTART_SERVICE_REQUIRED';
export const SET_FILTER: string = 'SET_FILTER';

export const userLoaded = (userSession?: IUserSession) => {
    return {
        type: USER_LOADED,
        payload: userSession,
    };
};

export const setCurrentMenuItem = (key: string) => {
    return {
        type: CURRENT_MENY_ITEM,
        payload: key,
    };
};

export const setRestartServiceRequired = (value: boolean) => {
    return {
        type: SET_RESTART_SERVICE_REQUIRED,
        payload: value,
    };
};

export const setFilter = (value: any, context: string) => {
    return {
        type: SET_FILTER,
        payload: value,
        context: context,
    };
};

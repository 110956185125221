import React, { useState, useEffect } from 'react';

import { Modal, Divider, Table, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    LoadingOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    CheckOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import Employee from '@components/users/employee';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@src/store/hooks';

import { serverFetch } from '@src/core/server';
import { IUser } from '@entities/user';

import { MobileIcon, MobileNoneIcon } from '@icons/index';

import './user.css';

interface IEmployeesProps {
    userId: string;
    userLogin?: string;
    isViewOnly: boolean;
}

const Employees = (props: IEmployeesProps) => {
    const { userId, userLogin, isViewOnly } = props;

    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [employees, setEmployees] = useState<Array<IUser>>([]);
    const [openEmployee, setOpenEmployee] = useState<boolean>(false);
    const [currentEmployee, setCurrentEmployee] = useState<IUser>();
    const [selectedEmployeeIds, setEmployeeSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setEmployees([]);

        setEmployeeSelectedIds([]);
        setCurrentEmployee(undefined);

        setRefreshRequired(false);

        setLoading(true);

        serverFetch(`users/${userId}/employees`, { method: 'GET' })
            .then((data) => {
                setEmployees(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения сотрудников', ex, () => d(userLoaded(undefined)));
            });
    }, [refreshRequired]);

    const onDeleteEmployee = () => {
        serverFetch(`users/${currentEmployee?.id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления сотрудника', ex, () => d(userLoaded(undefined)));
            });
    };

    const onEmployeeSelectChange = (selectedRowKeys: React.Key[]) => {
        setEmployeeSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = employees.find((c) => c.id === selectedRowKeys[0]);
            setCurrentEmployee(entity);
        } else {
            setCurrentEmployee(undefined);
        }
    };

    const renderEmployeeToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loading || !userId,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setOpenEmployee(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentEmployee || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setOpenEmployee(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentEmployee || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить сотрудника "${currentEmployee?.login}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteEmployee();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderEmployees = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Логин',
                dataIndex: 'login',
                width: 200,
            },
            {
                title: 'Разрешения',
                width: 150,
                align: 'left',
                render: (_, record) => {
                    return (
                        <ul>
                            {record.userSettings.showBills && <li>Счета</li>}
                            {record.userSettings.showNotifications && <li>Уведомления</li>}
                        </ul>
                    );
                },
            },
            {
                title: 'Активно',
                dataIndex: 'isActive',
                width: 80,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                width: 35,
                align: 'center',
                render: (_, record) => {
                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 300,
            },
            {},
        ];

        return (
            <div>
                <Divider orientation='left'>Сотрудники</Divider>

                {renderEmployeeToolbar()}

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={employees}
                    loading={{
                        spinning: loading,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedEmployeeIds,
                        onChange: onEmployeeSelectChange,
                        columnWidth: 35,
                        type: 'radio',
                    }}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onEmployeeSelectChange([record.id || '']);
                            },
                        };
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 20px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    return (
        <>
            {renderEmployees()}

            {openEmployee && (
                <Employee
                    id={currentEmployee?.id}
                    parentUserId={userId}
                    parentLogin={userLogin}
                    onClose={(refreshRequired: boolean) => {
                        setRefreshRequired(refreshRequired);
                        setEmployeeSelectedIds([]);
                        setCurrentEmployee(undefined);
                        setOpenEmployee(false);
                    }}
                />
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Employees;

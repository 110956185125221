import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useElementSize } from 'usehooks-ts';

import FormHeader from '@controls/form-header/form-header';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, Input, notification, Form, Drawer, Flex, Tooltip, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import { ReloadOutlined, WalletOutlined, HistoryOutlined, SwapOutlined, CalendarOutlined, CheckCircleFilled } from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Transactions from '@controls/transactions/transactions';
import DepositingModal from '@src/components/balances/depositing-modal';
import TransferAccountsModal from '@src/components/balances/transfer-accounts-modal';
import DebtWriteOffModal from '@src/components/balances/debt-write-off-modal';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { delayAction, toFinanceString } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserFilter } from '@entities/user-filter';
import { IUserBalanceDetail } from '@entities/user-balance-detail';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { IUser } from '@entities/user';
import { IUserAccount } from '@entities/user-account';

import { UnitType } from '@enums/unit-type';
import { WeightType } from '@enums/weight-type';

import { TruckIcon, AirplaneIcon, BoxesIcon, ServicePayIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

import { Currency, enumSign as currencySign } from '@enums/currency';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'BalanceDetails';

const BalanceDetails = () => {
    const { userId } = useParams();

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const tableRef = useRef<TableRef>(null);
    const [colorForm] = Form.useForm();

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const filter = useAppSelector<IUserFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [balances, setBalances] = useState<Array<IUserBalanceDetail>>([]);
    const [currentBalance, setCurrentBalance] = useState<IUserBalanceDetail>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [openDepositingDialog, setOpenDepositingDialog] = useState<boolean>(false);
    const [openDebtWriteOffDialog, setOpenDebtWriteOffDialog] = useState<boolean>(false);
    const [openTransferAccountsDialog, setOpenTransferAccountsDialog] = useState<boolean>(false);

    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);
    const [userAccounts, setUserAccounts] = useState<Array<IUserAccount>>([]);

    const [openTransactions, setOpenTransactions] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentBalance(undefined);
        setBalances([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`users/${userId}/balancedetails`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userId}/accounts`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setBalances(result[0][0]);
                setTariffSettings(result[0][1]);
                setUser(result[0][2]);
                setUserAccounts(result[0][3]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!filter) {
            d(setFilter({ isActive: true, isArchived: false }, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            setLastSelectedKey(selectedRowKeys[0]);

            let entity = balances.find((o) => o.userId == selectedRowKeys[0]);
            setCurrentBalance(entity);
        } else {
            setCurrentBalance(undefined);
        }
    };

    const onDepositin = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/depositing`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDepositingDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebtWriteOff = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/debtwriteoff`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDebtWriteOffDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            style={{ width: 150 }}
                            key='login'
                            placeholder='Логин'
                            value={filter?.login}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, login: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 300 }}
                            key='searchtText'
                            placeholder='Контрагент'
                            value={filter?.searchtText}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, searchtText: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter({ isActive: true, isArchived: false }, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Внести денежные средства на счет',
                        key: 'wallet',
                        icon: <WalletOutlined />,
                        onClick: () => {
                            setOpenDepositingDialog(true);
                        },
                    },
                    {
                        label: 'Погасить долг',
                        key: 'clodeDebt',
                        icon: <ServicePayIcon />,
                        onClick: () => {
                            setOpenDebtWriteOffDialog(true);
                        },
                    },
                    {
                        label: 'Перевод между счетами',
                        key: 'transfer',
                        icon: <SwapOutlined />,
                        onClick: () => {
                            setOpenTransferAccountsDialog(true);
                        },
                    },
                    {
                        label: 'История операций',
                        key: 'history',
                        icon: <HistoryOutlined />,
                        onClick: () => {
                            setOpenTransactions(true);
                        },
                    },
                ]}
                /*   farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]} */
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserBalanceDetail> = [
            {
                title: 'Отгрузка',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 130,
            },
            {
                title: '',
                align: 'center',
                width: 250,
                onCell: () => ({
                    style: {
                        paddingTop: 0,
                    },
                }),
                render: (_, record) => {
                    var diffQty: number = 0;
                    var useAdjustmentQty: boolean = false;

                    if (record.qty && record.unitType != UnitType.Weight) {
                        if (record.packages && record.packages.length > 0) {
                            var actualQty: number = 0;

                            record.packages &&
                                record.packages.map((p) => {
                                    actualQty += p.boxQty || 0;
                                });

                            diffQty = actualQty - record.qty;
                        } else {
                            useAdjustmentQty = true;
                        }
                    }

                    return (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                            <Form.Item
                                label={
                                    <Tooltip title='Количество коробок'>
                                        <BoxesIcon />
                                    </Tooltip>
                                }
                                className='country-form-item'
                                style={{ fontWeight: 600 }}
                            >
                                {record.qty}
                                {useAdjustmentQty ? (
                                    <span style={{ marginLeft: 2, marginRight: 2 }}>({record.adjustmentQty})</span>
                                ) : (
                                    diffQty != 0 && (
                                        <span style={{ color: diffQty > 0 ? 'green' : 'red', marginLeft: 2, marginRight: 2 }}>
                                            (<span>{diffQty > 0 && '+'}</span>
                                            <span>{diffQty}</span>)
                                        </span>
                                    )
                                )}
                                шт.
                            </Form.Item>
                            {record.loadingOn && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Дата отгрузки'>
                                            <CalendarOutlined style={{ fontSize: 18 }} />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                        {dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY')}
                                    </span>
                                </Form.Item>
                            )}
                            {record.awbNumber && (
                                <Form.Item label='AWB' className='country-form-item'>
                                    <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{record.awbNumber}</span>
                                </Form.Item>
                            )}
                            {record.cargoName && (
                                <Form.Item label={<AirplaneIcon style={{ fontSize: 14, marginLeft: 5 }} />} className='country-form-item'>
                                    <Tag color='var(--main-green)'>{record.cargoName}</Tag>
                                </Form.Item>
                            )}
                            {record.truckNumber && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Номер машины'>
                                            <TruckIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span>{record.isCombined ? record.combinedTruckNumbers : record.truckNumber}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            },
            {
                title: 'Вес Брутто',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.grossWeight && (
                            <>
                                {record.grossWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {setting && setting.weightType == WeightType.Gross && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: 'Объемный вес',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.volumeWeight && (
                            <>
                                {record.volumeWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {(!setting || setting.weightType == WeightType.Volume) && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: ' ',
                width: 30,
                align: 'center',
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div>
                            {record.flightPrice && (
                                <div className='flight-price'>
                                    <Tooltip title='Перелет' placement='topRight'>
                                        <div>
                                            <AirplaneIcon style={{ fontSize: 12 }} />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                            <div className={record.withFlight ? 'flight-price' : 'truck-price'}>
                                <Tooltip
                                    title={record.withFlight ? 'Транспортная доставка с перелетом' : 'Транспортная доставка'}
                                    placement='topRight'
                                >
                                    <div>
                                        <TruckIcon style={{ fontSize: 12, color: record.withFlight ? 'var(--main-green)' : '' }} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Тариф',
                width: 80,
                align: 'center',
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div>
                            {record.flightPrice && (
                                <div className='flight-price'>
                                    <span className='price-value'> {record.flightPrice}</span>
                                </div>
                            )}
                            <div className={record.withFlight ? 'flight-price' : 'truck-price'}>
                                <span className='price-value'>{record.truckPrice}</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Сумма ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'Скидка ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                        background: '#d2dcd8',
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice &&
                                record.totalPriceFinal &&
                                record.totalPrice > record.totalPriceFinal &&
                                toFinanceString(record.totalPrice - record.totalPriceFinal, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'К оплате ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>{toFinanceString(record.totalPriceFinal, 2)}</div>;
                },
            },
            {
                title: 'Баланс ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.balance < 0 ? 'var(--main-red)' : 'var(--main-green)' }}>
                            {record.balance && toFinanceString(record.balance, 2)}
                        </div>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={balances}
                pagination={false}
                virtual={true}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 237px - ${filterHeight}px)` }}>
            <FormHeader
                title={`Балансы "${user?.login}"`}
                container={
                    <Flex gap='large'>
                        {userAccounts.map((a) => {
                            return (
                                <Flex key={a.currency} align='center'>
                                    {a.currency == Currency.Rub ? (
                                        <RubleFilledIcon style={{ marginRight: 2 }} />
                                    ) : a.currency == Currency.Usd ? (
                                        <UsdFilledIcon style={{ marginRight: 2 }} />
                                    ) : (
                                        <EuroFilledIcon style={{ marginRight: 2 }} />
                                    )}
                                    <div style={{ fontSize: 18, fontWeight: 600 }}>{toFinanceString(a.amount || 0, 2)}</div>
                                </Flex>
                            );
                        })}
                    </Flex>
                }
            />

            {renderToolbar()}
            {/* {showFilter && renderFilter()} */}
            {renderTable()}

            {user && openDepositingDialog && (
                <DepositingModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    onCancel={() => setOpenDepositingDialog(false)}
                    onSave={(value: ITransactionParams) => onDepositin(value)}
                    api={api}
                />
            )}

            {user && openDebtWriteOffDialog && (
                <DebtWriteOffModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    onCancel={() => setOpenDebtWriteOffDialog(false)}
                    onSave={(value: ITransactionParams) => onDebtWriteOff(value)}
                    api={api}
                />
            )}

            {user && openTransferAccountsDialog && (
                <TransferAccountsModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    accounts={userAccounts}
                    onCancel={() => setOpenTransferAccountsDialog(false)}
                    onSave={(value: ITransactionParams) => onTransfer(value)}
                    api={api}
                />
            )}

            {user && openTransactions && (
                <Drawer
                    closable
                    destroyOnClose
                    title={`Операции "${user.login}"`}
                    placement='right'
                    open={true}
                    onClose={() => setOpenTransactions(false)}
                    width={1200}
                >
                    <Transactions userId={user.id || ''} isViewMode={false} />
                </Drawer>
            )}

            {notificationContextHolder}
        </div>
    );
};

export default BalanceDetails;

export enum Permission {
	FullAccess = 0,
	SendNotification = 1,

	LogisticGroup = -1,

	ManageTruck = 100,
	DeleteTruck = 101,
	ManageDeliveryStatus = 102,
	SetCommentTruck = 103,

	ManageTruckConsignment = 200,
	DeleteTruckConsignment = 201,
	CompleteTruckLoading = 202,

	WarehouseGroup = -3,

	ManageWarehouse = 300,
	DeleteWarehouseConsignment = 301,
	DeleteWarehouseBox = 302,
	DeleteShipmentJournal = 303,
	PrintDocuments = 304,
	FinishShipmentJournal = 305,
	CompleteAcceptanceForce = 306,
	AcceptBox = 307,
	RevertShipment = 308,

	ManageWMS = 400, // Warehouse Management System

	SetupGroup = -5,

	ViewSetup = 500,

	ManageUsers = 503,
	DeleteUser = 504,

	ManageWaypoints = 505,
	DeleteWaypoint = 506,

	ManageCities = 507,
	DeleteCity = 508,

	ManageCountries = 509,
	DeleteCountry = 510,

	ManageTags = 511,
	DeleteTag = 512,

	ManageWarehouses = 513,
	DeleteWarehouse = 514,

	ManagePackageTemplates = 515,
	DeletePackageTemplate = 516,

	ManageTradingPlatforms = 517,
	DeleteTradingPlatform = 518,

	ManageTransportTariffs = 519,
	DeleteTransportTariff = 520,

	ManageCompanies = 521,
	DeleteCompany = 522,

	ManageContacts = 523,
	DeleteContact = 524,

	ManageAccounts = 525,
	DeleteAccount = 526,

	ManageDebts = 527,
	DeleteDebt = 528,

	ManageCargoes = 529,
	DeleteCargo = 530,

	ManageConsignees = 601,
	DeleteConsignee = 602,
	LockConsignee = 603,
	RestoreConsignee = 604,
	TransferMarking = 605,
	AddSubClientAccount = 606,

	FinanceGroup = -7,
	SetPaid = 700,
	ManageTariff = 701,
	ManageTruckTariff = 702,
	ManageBillDebts = 703,
	AllowRefund = 704,
	AllowRevertPayment = 705,

	ManageTransactions = 706,
	DeleteTransaction = 707,
}

export const enumLabel = (value: Permission) => {
	switch (value) {
		case Permission.FullAccess:
			return 'Полный доступ';
		case Permission.SendNotification:
			return 'Отправка уведомлений';

		case Permission.LogisticGroup:
			return 'Логистика';
		case Permission.WarehouseGroup:
			return 'Склад';
		case Permission.SetupGroup:
			return 'Управление';
		case Permission.FinanceGroup:
			return 'Финансы';

		case Permission.ManageTruck:
			return 'Управление машиной';
		case Permission.DeleteTruck:
			return 'Удаление машины';
		case Permission.ManageDeliveryStatus:
			return 'Управление статусом доставки';
		case Permission.SetCommentTruck:
			return 'Комментаровать машину';

		case Permission.ManageTruckConsignment:
			return 'Управление партией';
		case Permission.DeleteTruckConsignment:
			return 'Удаление партии';
		case Permission.CompleteTruckLoading:
			return 'Завершение погрузки';

		case Permission.ManageWarehouse:
			return 'Управление складом';
		case Permission.DeleteWarehouseBox:
			return 'Удаление коробки';
		case Permission.DeleteWarehouseConsignment:
			return 'Удаление партии';
		case Permission.DeleteShipmentJournal:
			return 'Удаление журнала доставки';
		case Permission.PrintDocuments:
			return 'Печать документов';
		case Permission.FinishShipmentJournal:
			return 'Завершение журнала отгрузки';
		case Permission.CompleteAcceptanceForce:
			return 'Завершение приемки';
		case Permission.AcceptBox:
			return 'Принятие коробки';
		case Permission.RevertShipment:
			return 'Возврат отгрузки';

		case Permission.ManageWMS:
			return 'Управление ТСД';

		case Permission.ViewSetup:
			return 'Просмотр настроек';

		case Permission.ManageConsignees:
			return 'Управление грузополучателями';
		case Permission.DeleteConsignee:
			return 'Удаление грузополучателя';
		case Permission.LockConsignee:
			return 'Блокировка грузополучателя';
		case Permission.RestoreConsignee:
			return 'Восстановление грузополучателя';
		case Permission.TransferMarking:
			return 'Перенос маркировки';
		case Permission.AddSubClientAccount:
			return 'Создать личный кабинет субклиента';

		case Permission.ManageUsers:
			return 'Управление пользователями';
		case Permission.DeleteUser:
			return 'Удаление пользователя';
		case Permission.ManageWaypoints:
			return 'Управление маршрутной точками';
		case Permission.DeleteWaypoint:
			return 'Удаление маршрутной точки';
		case Permission.ManageCities:
			return 'Управление городами';
		case Permission.DeleteCity:
			return 'Удаление города';
		case Permission.ManageCountries:
			return 'Управление странами';
		case Permission.DeleteCountry:
			return 'Удаление страны';
		case Permission.ManageTags:
			return 'Управление тегами';
		case Permission.DeleteTag:
			return 'Удаление тега';
		case Permission.ManageWarehouses:
			return 'Управление складами';
		case Permission.DeleteWarehouse:
			return 'Удаление склада';
		case Permission.ManagePackageTemplates:
			return 'Управление упаковками';
		case Permission.DeletePackageTemplate:
			return 'Удаление упаковки';
		case Permission.ManageTradingPlatforms:
			return 'Управление торговыми площадками';
		case Permission.DeleteTradingPlatform:
			return 'Удаление торговой площадки';
		case Permission.ManageTransportTariffs:
			return 'Управление транспортными тарифами';
		case Permission.DeleteTransportTariff:
			return 'Удаление транспортного тарифа';
		case Permission.ManageCompanies:
			return 'Управление контрагентами';
		case Permission.DeleteCompany:
			return 'Удаление контрагента';
		case Permission.ManageContacts:
			return 'Управление контактами';
		case Permission.DeleteContact:
			return 'Удаление контакта';
		case Permission.ManageAccounts:
			return 'Управление расчетным счетом';
		case Permission.DeleteAccount:
			return 'Удаление расчетного счета';
		case Permission.ManageDebts:
			return 'Управление долгом';
		case Permission.DeleteDebt:
			return 'Удаление долга';
		case Permission.ManageCargoes:
			return 'Управление поставщиками';
		case Permission.DeleteCargo:
			return 'Удаление поставщика';

		case Permission.SetPaid:
			return 'Проведение оплаты';
		case Permission.ManageTruckTariff:
			return 'Управление расчетом машины';
		case Permission.ManageTariff:
			return 'Управление тарифом';
		case Permission.ManageBillDebts:
			return 'Управление задолженностями';
		case Permission.AllowRefund:
			return 'Возврат денег с баланса';
		case Permission.AllowRevertPayment:
			return 'Отменить платеж счета';

		case Permission.ManageTransactions:
			return 'Управление транзакциями';
		case Permission.DeleteTransaction:
			return 'Удаление транзакции';

		default:
			return '';
	}
};

export const hasPermissionOnly = (userPermissions: Array<Permission>, value: Permission) => {
	if (!userPermissions) return false;

	let result = userPermissions.some((p) => {
		if (p === value) {
			return true;
		}

		return false;
	});

	return result;
};

export const hasPermission = (userPermissions: Array<Permission>, value: Permission) => {
	if (!userPermissions) return false;

	let result = userPermissions.some((p) => {
		if (p === value) {
			return true;
		}

		if (hasAdmin(userPermissions)) {
			return true;
		}

		return false;
	});

	return result;
};

export const hasAdmin = (userPermissions: Array<Permission> = []) => {
	return userPermissions.some((p) => {
		if (p === Permission.FullAccess) {
			return true;
		}

		return false;
	});
};

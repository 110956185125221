import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Button, Input, Form, Space } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IDebt } from '@entities/debt';

const Debt = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<IDebt>({ name: '' });
    const [loading, setLoading] = useState<boolean>(false);
    const [manageCity] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageCities));

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`debts/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения долга', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onSave = () => {
        setLoading(true);

        serverFetch(`debts`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения долга', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader
                title={`${id ? (!manageCity ? 'Информация о долге' : 'Изменить долг') : 'Добавить долг'}`}
            />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название долга' }]}
                    >
                        <Input
                            disabled={!manageCity}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {manageCity && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Debt;

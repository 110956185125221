import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath, useParams } from 'react-router-dom';

import { Dayjs } from 'dayjs';

import { notification, DatePicker, Table, Select, Input, Tag, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ReloadOutlined, FilterFilled, UnorderedListOutlined, CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { getEnumList, delayAction } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { TruckStatus, enumLabel as truckStatusLabel } from '@enums/truck-status';
import { IOrderFilter } from '@entities/order-filter';
import { IOrder } from '@entities/order';
import { IUser } from '@entities/user';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Orders';

const Orders = () => {
    const { userId } = useParams();

    const { RangePicker } = DatePicker;

    const [modal, modalContextHolder] = Modal.useModal();

    const [api, notificationContextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const d = useAppDispatch();
    const filter = useAppSelector<IOrderFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState(true);
    const [orders, setOrders] = useState<Array<IOrder>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);

    const [currentItem, setCurrentItem] = useState<IOrder>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [statuses] = useState(getEnumList(TruckStatus, truckStatusLabel));
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('orders', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения заказов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/info/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setOrders(result[0][0]);

                setUser(result[0][1]);

                setRefreshRequired(false);
                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter({ userId: userId, statuses: [TruckStatus.New, TruckStatus.OnWay] }, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onForceDelivered = () => {
        serverFetch(`consignments/${currentItem?.boxGroupId}/forcedelivered`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка завершения заказа', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: `Детали`,
                        key: 'details',
                        disabled: !currentItem,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentItem?.boxGroupId}`);
                        },
                    },
                    {
                        label: 'Завершить заказ',
                        key: 'forceDelivered',
                        disabled: !currentItem,
                        type: 'primary',
                        icon: <CheckOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Вы хотите завершить выбранный заказ?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onForceDelivered();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 100 }}
                        key='consigneeCode'
                        placeholder='ID'
                        value={filter?.consigneeCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 120 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 150 }}
                        key='truckNumber'
                        placeholder='Машина'
                        value={filter?.truckNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, truckNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter?.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 200 }}
                        allowEmpty={[true, true]}
                        key='loading'
                        format='DD.MM.YYYY'
                        placeholder={['Загрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.loadingFrom ? dayjs(filter?.loadingFrom) : null,
                            filter?.loadingTo ? dayjs(filter?.loadingTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            loadingFrom: undefined,
                                            loadingTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            if (value[0]) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            loadingFrom: dayjs(value[0]).set('hour', 0).set('minute', 0).set('second', 0).toString(),
                                        },
                                        filterContext
                                    )
                                );
                            }

                            if (value[1]) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            loadingTo: dayjs(value[1]).set('hour', 23).set('minute', 59).set('second', 59).toString(),
                                        },
                                        filterContext
                                    )
                                );
                            }
                        }}
                    />,
                    <RangePicker
                        style={{ width: 200 }}
                        allowEmpty={[true, true]}
                        key='departure'
                        format='DD.MM.YYYY'
                        placeholder={['Отправка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.departureFrom ? dayjs(filter?.departureFrom) : null,
                            filter?.departureTo ? dayjs(filter?.departureTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            departureFrom: undefined,
                                            departureTo: undefined,
                                        },
                                        filterContext
                                    )
                                );

                                return;
                            }

                            if (value[0]) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            departureFrom: dayjs(value[0]).set('hour', 0).set('minute', 0).set('second', 0).toString(),
                                        },
                                        filterContext
                                    )
                                );
                            }

                            if (value[1]) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            departureTo: dayjs(value[1]).set('hour', 23).set('minute', 59).set('second', 59).toString(),
                                        },
                                        filterContext
                                    )
                                );
                            }
                        }}
                    />,
                ]}
                onClear={() => d(setFilter({ statuses: [TruckStatus.New, TruckStatus.OnWay] }, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length === 1) {
            let item = orders.find((o) => o.boxGroupId === selectedRowKeys[0]);
            setCurrentItem(item);
        } else {
            setCurrentItem(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IOrder> = [
            {
                title: 'ID',
                align: 'center',
                dataIndex: 'consigneeCode',
                width: 60,
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 180,
            },
            {
                title: 'Страна',
                width: 150,
                dataIndex: 'countryName',
            },
            {
                title: 'AWB',
                align: 'center',
                width: 130,
                render: (_, record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'boxQty',
                onCell: (record) => ({
                    style: {
                        background: '#FFDD2D',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: 'Догруз',
                width: 70,
                align: 'center',
                render: (_: any, record: IOrder) => {
                    return record.isExtraConsignment && <CheckOutlined />;
                },
            },
            {
                title: 'Отгрузка',
                width: 120,
                align: 'center',
                render: (_: any, record: IOrder) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: 'Статус',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IOrder) => {
                    if (record.truckStatus === TruckStatus.New) {
                        return <span style={{ background: '#FFDD2D', padding: '1px 4px 2px', marginLeft: 10 }}>Формирование заказа</span>;
                    }
                    return (
                        <>
                            {record.waypointName && (
                                <span style={{ background: '#FFDD2D', padding: '1px 4px 2px', marginLeft: 10 }}>{record.waypointName}</span>
                            )}
                        </>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='boxGroupId'
                size='small'
                columns={columns}
                dataSource={orders}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.boxGroupId || '']);
                        },
                    };
                }}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})` }}
            />
        );
    };

    return (
        <>
            <FormHeader title={`Заказы "${user?.login}"`} />
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {notificationContextHolder}
            {modalContextHolder}
        </>
    );
};

export default Orders;

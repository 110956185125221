import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Space, Button, Select, Input, Form, Modal, Checkbox, InputNumber, Flex, Tooltip, AutoComplete, Tag, notification } from 'antd';

import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';

import { exception, noAccessError } from '@extensions/notification';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ICountry } from '@entities/country';
import { IMarking } from '@entities/marking';
import { IConsignment } from '@entities/consignment';
import { IBoxGroup } from '@entities/box-group';
import { ITradingPlatform } from '@entities/trading-platform';
import { ICargo } from '@entities/cargo';

import { Permission, hasPermission } from '@enums/permission';

import { HoldIcon, AirplaneIcon } from '@src/core/icons';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IOnHoldParams {
    onHoldReason?: string;
}

interface IOption {
    value: string;
    label: string;
}

const Consignment = () => {
    const navigate = useNavigate();

    const [onHoldForm] = Form.useForm();
    const [platformForm] = Form.useForm();

    const { TextArea } = Input;
    const [api, contextHolder] = notification.useNotification();

    const { truckId, consignmentId } = useParams();

    const [form] = Form.useForm();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [markings, setMarkings] = useState<Array<IMarking>>([]);
    const [sourcePlatforms, setSourcePlatforms] = useState<Array<ITradingPlatform>>([]);
    const [platforms, setPlatforms] = useState<Array<ITradingPlatform>>([]);
    const [options, setOptions] = useState<Array<IOption>>([]);

    const [boxGroups, setBoxGroups] = useState<Array<IBoxGroup>>([]);

    const [currentBoxGroup, setCurrentBoxGroup] = useState<IBoxGroup>();

    const [entity, setEntity] = useState<IConsignment>({ id: undefined, markingId: undefined });

    const [loading, setLoading] = useState<boolean>(false);
    const [onHoldOpen, setOnHoldOpen] = useState<boolean>(false);
    const [platformOpen, setPlatformOpen] = useState<boolean>(false);

    const [manageTruckConsignment] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTruckConsignment));

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [onHoldProcessing, setOnHoldProcessing] = useState<boolean>(false);

    const [cargoes, setCargoes] = useState<Array<ICargo>>([]);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('cargoes/all', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения перевозчиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`tradingplatforms`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения торговых площадок', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (consignmentId) {
                promises.push(
                    await serverFetch(`consignments/${consignmentId}/import`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCargoes(result[0][0]);

                let loadedCountries: Array<ICountry> = result[0][1].filter((c: ICountry) => c.generateTariff);
                setCountries(loadedCountries);
                setSourcePlatforms(result[0][2]);
                setMarkings(result[0][3]);

                let data: IConsignment = result[0][4] || { ...entity, truckId: truckId };

                var entities: Array<IBoxGroup> = [];

                loadedCountries.map((c: ICountry) => {
                    let countryBoxGroups = data.boxGroups?.filter((b) => b.countryId === c.id);

                    if (countryBoxGroups && countryBoxGroups.length > 0) {
                        countryBoxGroups?.map((bg) => {
                            form.setFieldsValue({
                                cityId: bg.cityId,
                            });

                            entities.push({
                                ...bg,
                                key: `${c.id}#${bg?.awbNumber}`,
                                countryId: c.id,
                                countryName: c.name,
                                countryCode: c.code,
                            });
                        });
                    } else {
                        entities.push({
                            key: `${c.id}`,
                            id: undefined,
                            consignmentId: consignmentId,
                            countryId: c.id,
                            countryName: c.name,
                            countryCode: c.code,
                            qty: undefined,
                            calcVolume: undefined,
                            loadingOn: undefined,
                            awbNumber: undefined,
                        });
                    }
                });

                setBoxGroups(entities);

                if (consignmentId) {
                    form.setFieldsValue({
                        markingId: data.markingId,
                        cityId: data.cityId,
                        consigneeCode: data.consigneeCode,
                    });
                }

                setEntity({
                    id: data.id,
                    markingId: data.markingId,
                    truckId: data.truckId,
                    cityId: data.cityId,
                    comment: data.comment,
                    fromPlatina: data.fromPlatina,
                });

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        let items: Array<IOption> = [];

        platforms.map((p: ITradingPlatform) => {
            let newItem: IOption = {
                value: `${p.name}`,
                label: `${p.name}`,
            };

            items.push(newItem);
        });

        setOptions(items);
    }, [platforms]);

    const loadPlatforms = () => {
        serverFetch(`tradingplatforms`, { method: 'GET' })
            .then((data) => {
                setSourcePlatforms(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения торговых площадок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSavePlatform = (entity: ITradingPlatform) => {
        serverFetch(`tradingplatforms`, { method: 'POST', bodyData: entity })
            .then(() => {
                platformForm.resetFields();
                setPlatformOpen(false);
                loadPlatforms();
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения торговой площадки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onMarkingChanged = async (value: string | undefined) => {
        let marking = markings.find((m) => m.id == value);
        if (!marking) return;

        form.setFieldsValue({
            markingId: value,
            cityId: marking.cityId,
        });
    };

    const onFinish = (data: IConsignment) => {
        setLoading(true);

        boxGroups.map((bg) => {
            bg.cityId = data.cityId;
        });

        let result = { ...entity, ...data, boxGroups: boxGroups.filter((bg) => bg.qty && bg.qty > 0) };

        serverFetch(`consignments`, { method: consignmentId ? 'PUT' : 'POST', bodyData: result })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetOnHold = (entity: IOnHoldParams) => {
        if (!currentBoxGroup) return;

        setOnHoldProcessing(true);

        serverFetch(`remainings/onhold`, {
            method: 'POST',
            bodyData: {
                ids: [currentBoxGroup.id],
                onHoldReason: entity.onHoldReason,
            },
        })
            .then(() => {
                setOnHoldProcessing(false);

                onHoldForm.resetFields();
                setCurrentBoxGroup(undefined);
                setOnHoldOpen(false);

                setRefreshRequired(true);
            })
            .catch((ex) => {
                setOnHoldProcessing(false);
                exception(api, 'Ошибка блокировки грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderBoxGroupsForm = () => {
        let groups = [...boxGroups];

        return groups.map((bg: IBoxGroup) => {
            return (
                <Form.Item
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 20 }}
                    key={bg.key}
                    label={`${bg.countryName}`}
                    style={{ marginBottom: 0 }}
                >
                    <Flex gap='middle'>
                        <Form.Item initialValue={bg.qty} name={`${bg.key}qty`} style={{ width: 140, minWidth: 140 }}>
                            <InputNumber
                                placeholder='Количество'
                                disabled={!manageTruckConsignment}
                                addonAfter='шт'
                                min={0}
                                onChange={(value: number | null) => {
                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                    if (boxGroup) {
                                        boxGroup.qty = value;
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={bg.grossWeight} name={`${bg.key}grossWeight`} style={{ width: 170, minWidth: 170 }}>
                            <InputNumber
                                decimalSeparator=','
                                min={0}
                                placeholder='Брутто'
                                disabled={!manageTruckConsignment}
                                stringMode
                                step={bg.countryCode === 'NL' ? '0.01' : '1'}
                                addonAfter={bg.countryCode === 'NL' || bg.countryCode === 'TL' ? 'пл' : 'кг'}
                                onChange={(value: number | null) => {
                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                    if (boxGroup) {
                                        boxGroup.grossWeight = value;
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item initialValue={bg.volumeWeight} name={`${bg.key}volumeWeight`} style={{ width: 170, minWidth: 170 }}>
                            <InputNumber
                                decimalSeparator=','
                                placeholder='Объемный'
                                min={0}
                                disabled={!manageTruckConsignment}
                                stringMode
                                step={bg.countryCode === 'NL' ? '0.01' : '1'}
                                addonAfter={bg.countryCode === 'NL' || bg.countryCode === 'TL' ? 'пл' : 'кг'}
                                onChange={(value: number | null) => {
                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                    if (boxGroup) {
                                        boxGroup.volumeWeight = value;
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item name={`${bg.key}awbNumber`} initialValue={bg.awbNumber} style={{ width: 250, minWidth: 250 }}>
                            {bg.countryCode === 'NL' || bg.countryCode === 'TL' ? (
                                <Space.Compact style={{ width: '100%' }}>
                                    <AutoComplete
                                        placeholder='Торговая площадка'
                                        disabled={!manageTruckConsignment}
                                        popupMatchSelectWidth={true}
                                        options={options}
                                        onSelect={(value: string) => {
                                            let boxGroup = groups.find((d) => d.key === bg.key);
                                            if (boxGroup) {
                                                boxGroup.awbNumber = value;
                                            }
                                        }}
                                        onSearch={async (value: string) => {
                                            setOptions([]);
                                            var result = sourcePlatforms.filter(
                                                (p) => p.countryId == bg.countryId && p.name?.toUpperCase()?.startsWith(value.toUpperCase())
                                            );
                                            result ? setPlatforms(result) : setPlatforms([]);
                                        }}
                                    />
                                    <Tooltip title='Добавить торговую площадку'>
                                        <Button icon={<PlusOutlined />} onClick={() => setPlatformOpen(true)} />
                                    </Tooltip>
                                </Space.Compact>
                            ) : (
                                <Input
                                    placeholder='Номер AWB'
                                    disabled={!manageTruckConsignment}
                                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                        let boxGroup = groups.find((d) => d.key === bg.key);
                                        if (boxGroup) {
                                            boxGroup.awbNumber = value.target.value;
                                        }
                                    }}
                                    addonAfter={
                                        <Tooltip title='AWB сперелетом'>
                                            <AirplaneIcon style={{ fontSize: 14, marginRight: 8 }} />
                                            <Checkbox
                                                defaultChecked={bg.withFlight}
                                                disabled={!manageTruckConsignment}
                                                onChange={(value) => {
                                                    let boxGroup = groups.find((d) => d.key === bg.key);
                                                    if (boxGroup) {
                                                        boxGroup.withFlight = value.target.checked;
                                                    }

                                                    setBoxGroups(groups);
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            )}
                        </Form.Item>

                        {bg.countryCode !== 'NL' && bg.countryCode !== 'TL' && (
                            <Form.Item
                                name={`${bg.key}cargoId`}
                                initialValue={bg.cargoId}
                                style={{ width: 150, minWidth: 150 }}
                                rules={[{ required: bg.withFlight, message: 'Укажите поставщика' }]}
                            >
                                <Select
                                    allowClear
                                    placeholder='Поставщик'
                                    disabled={!manageTruckConsignment || !bg.withFlight}
                                    onChange={(value) => {
                                        let boxGroup = groups.find((d) => d.key === bg.key);
                                        if (boxGroup) {
                                            boxGroup.cargoId = value;
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={cargoes
                                        .filter((c) => c.countryId == bg.countryId)
                                        .map((c) => {
                                            return { value: c.id, label: c.name };
                                        })}
                                />
                            </Form.Item>
                        )}

                        {bg.id && (
                            <Tooltip title={bg.onHold ? 'Разблокировать' : 'Заблокировать'}>
                                <Button
                                    style={{ width: 35, minWidth: 35, color: bg.onHold ? 'darkorchid' : '' }}
                                    icon={<HoldIcon />}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                            noAccessError(api, [Permission.ManageWarehouse]);
                                            return;
                                        }

                                        setCurrentBoxGroup(bg);

                                        setOnHoldOpen(true);
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Flex>
                </Form.Item>
            );
        });
    };

    return (
        <>
            <FormHeader title={`${consignmentId ? 'Изменить партию' : 'Добавить партию'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 16 }} form={form} onFinish={onFinish}>
                    <Form.Item name='cityId' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 2 }} initialValue={entity.consigneeCode} label='ID' name='consigneeCode'>
                        <Input disabled={true} suffix={entity.fromPlatina ? <Tag color='#000000'>#PL</Tag> : <></>} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{ span: 5 }}
                        initialValue={entity.markingId}
                        required
                        label='Маркировка'
                        name='markingId'
                        rules={[{ required: true, message: 'Выберите маркировку' }]}
                    >
                        <Select
                            disabled={!manageTruckConsignment}
                            autoFocus
                            showSearch
                            onChange={(value) => onMarkingChanged(value)}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={markings.map((m) => {
                                return { value: m.id, label: `${m.code} / ${m.cityName}` };
                            })}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 9 }} initialValue={entity.comment} label='Комментарий' name='comment'>
                        <TextArea
                            rows={3}
                            disabled={!manageTruckConsignment}
                            onChange={(data) => {
                                setEntity({ ...entity, comment: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 1, span: 18 }}>{renderBoxGroupsForm()}</Form.Item>

                    <Form.Item wrapperCol={{ offset: 1, span: 11 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Закрыть
                            </Button>
                            {manageTruckConsignment && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            <Modal
                title={
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'start' }}>
                        <QuestionCircleOutlined style={{ color: '#faad14', marginInlineEnd: 12, fontSize: 22 }} />
                        {currentBoxGroup?.onHold
                            ? `Разблокировать '${currentBoxGroup.countryName}'?`
                            : `Заблокировать '${currentBoxGroup?.countryName}'?`}
                    </div>
                }
                open={onHoldOpen}
                okText='Сохранить'
                onOk={() => onHoldForm.submit()}
                onCancel={() => {
                    onHoldForm.resetFields();
                    setCurrentBoxGroup(undefined);
                    setOnHoldOpen(false);
                }}
                closable={false}
                cancelButtonProps={{ style: { display: onHoldProcessing ? 'none' : '' } }}
                confirmLoading={onHoldProcessing}
            >
                {!currentBoxGroup?.onHold && (
                    <Form
                        colon={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        onFinish={onSetOnHold}
                        form={onHoldForm}
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item
                            required={true}
                            label='Причина'
                            name='onHoldReason'
                            rules={[{ required: true, message: 'Укажите причину блокировки' }]}
                        >
                            <TextArea autoFocus rows={4} />
                        </Form.Item>
                    </Form>
                )}
            </Modal>

            <Modal
                title='Торговая площадка'
                open={platformOpen}
                okText='ОК'
                onOk={() => platformForm.submit()}
                onCancel={() => {
                    platformForm.resetFields();

                    setPlatformOpen(false);
                }}
            >
                <Form colon={false} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onSavePlatform} form={platformForm}>
                    <Form.Item required label='Страна' name='countryId' rules={[{ required: true, message: 'Выберите страну' }]}>
                        <Select
                            autoFocus
                            showSearch
                            //onChange={(value) => setEntity({ ...entity, countryId: value })}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={countries.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        ></Select>
                    </Form.Item>
                    <Form.Item
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название торговой площадки' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
        </>
    );
};

export default Consignment;

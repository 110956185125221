import React, { useState, useEffect } from 'react';

import { useElementSize } from 'usehooks-ts';

import { Table, Input, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CheckOutlined, LoadingOutlined, ReloadOutlined, FilterFilled } from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';
import { delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';

import { serverFetch } from '@src/core/server';
import { ICompany } from '@entities/company';
import { ICompanyFilter } from '@entities/company-filter';

const filterContext: string = 'Companies';

const Companies = () => {
    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [currentCompany, setCurrentCompany] = useState<ICompany>();

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const filter = useAppSelector<ICompanyFilter>((s) => s.filters[filterContext]);

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            setCompanies([]);
            setSelectedIds([]);
            setCurrentCompany(undefined);

            let promises = [
                await serverFetch('companies', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения контрагентов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCompanies(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter({}, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = companies.find((o) => o.id === selectedRowKeys[0]);
            setCurrentCompany(entity);
        } else {
            setCurrentCompany(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            style={{ width: 250 }}
                            key='searchtText'
                            placeholder='ИНН / Название / Логин / ФИО '
                            value={filter?.searchtText}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchtText: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter({}, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ICompany> = [
            {
                title: 'ИНН',
                dataIndex: 'inn',
                width: 160,
            },
            {
                title: 'Название',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'Юр. адрес',
                width: 300,
                dataIndex: 'legalAddress',
            },
            {
                title: 'Основной',
                width: 90,
                align: 'center',
                render: (_: any, record) => {
                    return record.isMain ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Логин',
                dataIndex: 'userLogin',
                width: 250,
            },
            {
                title: 'ФИО',
                dataIndex: 'userFullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'userPhone',
                width: 160,
            },
            {
                title: 'Email',
                dataIndex: 'userEmail',
                width: 300,
            },
            {},
        ];

        return (
            <Table
                virtual={true}
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={companies}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {contextHolder}
        </div>
    );
};

export default Companies;

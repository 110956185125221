import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Switch, Input, Form, Space, Divider, Select, Row, Col, notification, Flex, InputNumber, Tabs } from 'antd';

import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';

import Employees from '@components/users/employees';
import Companies from '@components/users/companies';
import Discounts from '@components/users/discounts';
import Debts from '@components/users/debts';

import { getEnumList, toFinanceString } from '@extensions/utils';

import { exception, success } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@entities/user-permission';
import { IWarehouse } from '@entities/warehouse';
import { IUserDeliverySetting } from '@entities/user-delivery-setting';
import { IUserAccount } from '@entities/user-account';
import { IUserDebt } from '@entities/user-debt';
import { IUserDebtFilter } from '@entities/user-debt-filter';

import { UserType, enumLabel as userTypeLabel } from '@enums/user-type';
import { Permission, hasPermission, enumLabel as permissionLabel } from '@enums/permission';
import { DayOfWeek, enumLabel as dayOfWeekLabel } from '@enums/day-of-week';
import { Currency, enumSign as currencySign } from '@src/core/enums/currency';
import { IEnumItem } from '@enums/enum-item';

import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';
import { ExchangeRateCommissionType } from '@enums/exchange-rate-commission-type';

import './user.css';

interface IUserParams {
    user: IUser;
    userPermissions: Array<IUserPermission>;
}

interface IDay {
    label: string;
    type: DayOfWeek;
    checked: boolean;
}

const User = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [userId, setUserId] = useState<string | undefined>(id);
    const [entity, setEntity] = useState<IUser>();

    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [allowSetPassword, setAllowSetPassword] = useState<boolean>(!userId);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageUsers));
    const [userTypes, setUserTypes] = useState<Array<IEnumItem>>(
        getEnumList(UserType, userTypeLabel).filter((t) => t.value == UserType.System || t.value == UserType.Supplier)
    );

    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);
    const [days, setDays] = useState<Array<IDay>>([]);

    const [accounts, setAccounts] = useState<Array<IUserAccount>>([]);

    const [loadedUserPermissions, setLoadedUserPermissions] = useState<Array<IUserPermission>>();
    const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([]);
    const [permissions] = useState<Array<IEnumItem>>(getEnumList(Permission, permissionLabel));
    const [dayOfWeeks] = useState<Array<IEnumItem>>(getEnumList(DayOfWeek, dayOfWeekLabel));
    const [phone, setPhone] = useState<string>();
    const [userDebtsRefreshRequired, setUserDebtsRefreshRequired] = useState<boolean>();
    const [rateCommissionTypes] = useState<Array<IEnumItem>>(getEnumList(ExchangeRateCommissionType));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (userId) {
                promises.push(
                    await serverFetch(`users/${userId}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${userId}/permissions`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения прав', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${userId}/deliverysetting`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения настроек доставки', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${userId}/accounts`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWarehouses(result[0][0] || []);

                let user = result[0][1];
                if (user) {
                    setEntity(user);
                    setPhone(user.phone);
                } else {
                    setEntity({
                        id: undefined,
                        login: undefined,
                        email: undefined,
                        fullName: undefined,
                        isActive: undefined,
                        phone: undefined,
                        isArchived: false,
                        type: UserType.System,
                        userSettings: {
                            useInternalExchangeRate: false,
                            showBills: false,
                            showNotifications: false,
                        },
                    });
                }

                if (user && user.id && user.type != UserType.System) {
                    setUserDebtsRefreshRequired(true);
                }

                if (user && user.type == UserType.MainClient) {
                    setUserTypes([{ value: UserType.MainClient, label: userTypeLabel(UserType.MainClient) }]);
                }

                let userPermissions = result[0][2];

                if (userPermissions) {
                    setLoadedUserPermissions(userPermissions);
                } else {
                    setLoadedUserPermissions([]);
                }

                let deliverySetting: IUserDeliverySetting = result[0][3];
                let selectedDays: Array<DayOfWeek> = [];

                if (deliverySetting) {
                    selectedDays = JSON.parse(deliverySetting.daysOfWeek);
                }

                let tmpDays: Array<IDay> = [];
                dayOfWeeks.map((d) => {
                    let selectedDay = selectedDays.find((s) => s == d.value);

                    let day: IDay = {
                        type: d.value,
                        label: d.label,
                        checked: selectedDay != undefined,
                    };

                    tmpDays.push(day);
                });

                setDays(tmpDays);

                setAccounts(result[0][4]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!userDebtsRefreshRequired) return;

        setUserDebts([]);

        setUserDebtsRefreshRequired(false);

        var userDebtFilter: IUserDebtFilter = {
            userId: userId,
        };

        serverFetch(`userdebts`, { method: 'GET', queryParams: userDebtFilter })
            .then((data) => {
                setUserDebts(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения долгов перед клиентом', ex, () => d(userLoaded(undefined)));
            });
    }, [userDebtsRefreshRequired]);

    useEffect(() => {
        if (loadedUserPermissions) {
            let entities: Array<IUserPermission> = [];
            permissions.map((p) => {
                let permission = loadedUserPermissions.find((e) => e.permissionCode === p.value);
                if (permission) {
                    entities.push({ userId: userId as string, name: p.label, permissionCode: p.value, isActive: true });
                } else {
                    entities.push({ userId: userId as string, name: p.label, permissionCode: p.value, isActive: false });
                }
            });

            setUserPermissions(entities);
        }
    }, [loadedUserPermissions]);

    const onFinish = () => {
        if (!entity) return;

        setLoading(true);

        let activeUserPermissions = userPermissions.filter((p) => p.isActive);
        let activeDays = days.filter((p) => p.checked).map((d) => d.type);

        let deliverySetting: IUserDeliverySetting = {
            userId: userId,
            daysOfWeek: JSON.stringify(activeDays),
        };

        entity.phone = phone;

        let data: IUserParams = {
            user: entity,
            userPermissions: activeUserPermissions,
        };

        serverFetch(`users`, { method: userId ? 'PUT' : 'POST', bodyData: data })
            .then((data) => {
                if (!userId) setUserId(data);

                setLoading(false);
                success(api, 'Изменения успешно сохранены');
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRestore = () => {
        serverFetch(`users/restore/${userId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка восстановления пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderPermissions = () => {
        return (
            <div>
                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider style={{ marginTop: 0 }} orientation='left'>
                        Разрешения
                    </Divider>
                </Form.Item>

                {userPermissions.map((p) => {
                    if (p.permissionCode < 0) {
                        return (
                            <Form.Item key={p.permissionCode} wrapperCol={{ span: 9 }}>
                                <Divider>{p.name}</Divider>
                            </Form.Item>
                        );
                    }

                    return (
                        <Form.Item labelAlign='left' key={p.permissionCode} label={p.name || p.permissionCode} labelCol={{ span: 8 }}>
                            <Switch
                                disabled={isViewOnly}
                                defaultChecked={p.isActive}
                                onChange={(value) => {
                                    let entities = [...userPermissions];

                                    let entity = entities.find((e) => e.permissionCode === p.permissionCode);

                                    if (entity) {
                                        entity.isActive = value;

                                        setUserPermissions([...entities]);
                                    }
                                }}
                            />
                        </Form.Item>
                    );
                })}
            </div>
        );
    };

    const renderAccounts = () => {
        return (
            accounts &&
            accounts.length > 0 && (
                <div>
                    <Divider orientation='left'>Cчета</Divider>

                    <Flex justify='flex-start' align='center' gap='large'>
                        {accounts.map((a) => {
                            return (
                                <Flex key={a.currency} justify='center'>
                                    {a.currency == Currency.Rub ? (
                                        <RubleFilledIcon />
                                    ) : a.currency == Currency.Usd ? (
                                        <UsdFilledIcon />
                                    ) : (
                                        <EuroFilledIcon />
                                    )}
                                    <div style={{ fontSize: 18, fontWeight: 600 }}>{toFinanceString(a.amount || 0, 2)}</div>
                                </Flex>
                            );
                        })}
                    </Flex>
                </div>
            )
        );
    };

    const renderSettings = () => {
        return (
            <>
                <Form.Item wrapperCol={{ span: 9 }}>
                    <Divider orientation='left' style={{ marginBottom: 0, marginTop: 0 }}>
                        Операции с валютой
                    </Divider>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    label='Использовать внутренний курс'
                    initialValue={entity?.userSettings?.useInternalExchangeRate}
                    name='useInternalExchangeRate'
                    labelCol={{ span: 8 }}
                >
                    <Switch
                        disabled={isViewOnly}
                        onChange={(value) => {
                            if (!entity) return;
                            setEntity({ ...entity, userSettings: { ...entity.userSettings, useInternalExchangeRate: value } });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    labelAlign='left'
                    label='Комиссия за конвертацию валюты'
                    initialValue={entity?.userSettings?.exchangeRateCommission}
                    name='exchangeRateCommission'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 3 }}
                >
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        onChange={(value: number | null) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, exchangeRateCommission: value } });
                        }}
                        addonAfter={
                            <Select
                                defaultValue={entity?.userSettings?.exchangeRateCommissionType || ExchangeRateCommissionType.Percentage}
                                disabled={isViewOnly}
                                onChange={(value: ExchangeRateCommissionType) => {
                                    if (!entity) return;

                                    setEntity({ ...entity, userSettings: { ...entity.userSettings, exchangeRateCommissionType: value } });
                                }}
                                options={rateCommissionTypes.map((c) => {
                                    return {
                                        value: c.value,
                                        label: c.value == ExchangeRateCommissionType.Percentage ? '%' : currencySign(Currency.Rub),
                                    };
                                })}
                            />
                        }
                    />
                </Form.Item>

                <Form.Item
                    labelAlign='left'
                    label='Комиссия за внутренний перевод'
                    initialValue={entity?.userSettings?.internalTransferCommission}
                    name='transferCommission'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 3 }}
                >
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        onChange={(value: number | null) => {
                            if (!entity) return;
                            setEntity({ ...entity, userSettings: { ...entity.userSettings, internalTransferCommission: value } });
                        }}
                        addonAfter='%'
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 9 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Способ оплаты
                    </Divider>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='useCashPayments'
                    label='Наличный'
                    initialValue={entity?.useCashPayments}
                    valuePropName='checked'
                    name='useCashPayments'
                    style={{ marginBottom: 0 }}
                    labelCol={{ span: 8 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, useCashPayments: value });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='useNonCashPayments'
                    label='Безналичный'
                    initialValue={entity?.useNonCashPayments}
                    valuePropName='checked'
                    name='useNonCashPayments'
                    labelCol={{ span: 8 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, useNonCashPayments: value });
                        }}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 9 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Разрешения
                    </Divider>
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='showBills'
                    label='Счета'
                    initialValue={entity?.userSettings?.showBills}
                    valuePropName='checked'
                    name='showBills'
                    style={{ marginBottom: 0 }}
                    labelCol={{ span: 8 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, showBills: value } });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    labelAlign='left'
                    key='showNotifications'
                    label='Уведомления'
                    initialValue={entity?.userSettings?.showNotifications}
                    valuePropName='checked'
                    name='showNotifications'
                    labelCol={{ span: 8 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, showNotifications: value } });
                        }}
                    />
                </Form.Item>

                {entity?.type != UserType.Employee && (
                    <>
                        <Form.Item wrapperCol={{ span: 9 }}>
                            <Divider orientation='left' style={{ marginBottom: 0 }}>
                                Дни доставки
                            </Divider>
                        </Form.Item>

                        {days.map((s) => {
                            return (
                                <Form.Item
                                    labelAlign='left'
                                    key={s.type}
                                    label={s.label}
                                    initialValue={s.checked}
                                    valuePropName='checked'
                                    name={s.label}
                                    style={{ marginBottom: 0 }}
                                    labelCol={{ span: 8 }}
                                >
                                    <Switch
                                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                                        onChange={(value) => {
                                            let tmpDays = [...days];
                                            let day = tmpDays.find((e) => e.type === s.type);

                                            if (day) day.checked = value;

                                            setDays(tmpDays);
                                        }}
                                    />
                                </Form.Item>
                            );
                        })}
                    </>
                )}
            </>
        );
    };

    const renderUserForm = () => {
        return (
            entity && (
                <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFinish} style={{ marginTop: 18 }}>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                initialValue={entity?.login}
                                required
                                validateFirst={true}
                                label='Логин'
                                name='login'
                                rules={[
                                    { required: true, message: 'Укажите логин' },
                                    {
                                        validator: async (_, value) => {
                                            let isEmailExist = await serverFetch(`users/loginexist/${userId}/${value}`, {
                                                method: 'GET',
                                            })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки логина', ex, () => d(userLoaded(undefined)));
                                                });

                                            if (!userId && isEmailExist)
                                                return Promise.reject('Учетная запись с указанным логином уже существует');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    disabled={isViewOnly || !!userId}
                                    onChange={(data) => {
                                        setEntity({ ...entity, login: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                required
                                label='Пароль'
                                name='newPassword'
                                rules={[{ required: !userId, message: 'Укажите пароль' }]}
                            >
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input.Password
                                        placeholder={userId && !allowSetPassword ? '*******' : ''}
                                        disabled={!allowSetPassword || isViewOnly}
                                        onChange={(data) => {
                                            setEntity({ ...entity, newPassword: data.target.value });
                                        }}
                                    />
                                    {userId && (
                                        <Button
                                            disabled={isViewOnly}
                                            type='primary'
                                            onClick={() => {
                                                setAllowSetPassword(true);
                                            }}
                                        >
                                            Сменить пароль
                                        </Button>
                                    )}
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.type}
                                required
                                label='Тип'
                                name='type'
                                rules={[{ required: true, message: 'Укажите тип' }]}
                            >
                                <Select
                                    disabled={isViewOnly || entity.type == UserType.MainClient || entity.type == UserType.SubClient}
                                    onChange={(value: UserType) => {
                                        setEntity({ ...entity, type: value });
                                    }}
                                    options={userTypes.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            </Form.Item>
                            {entity?.type == UserType.System && (
                                <Form.Item initialValue={entity.warehouseId} label='Склад' name='warehouse'>
                                    <Select
                                        allowClear
                                        disabled={isViewOnly}
                                        showSearch
                                        onChange={(value: string) => setEntity({ ...entity, warehouseId: value })}
                                        optionFilterProp='children'
                                        filterOption={(input: any, option: any) =>
                                            (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        filterSort={(a: any, b: any) =>
                                            (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                        }
                                        options={warehouses.map((w) => {
                                            return { value: w.id, label: `${w.code} (${w.cityName})` };
                                        })}
                                    />
                                </Form.Item>
                            )}
                            <Form.Item
                                initialValue={entity?.fullName}
                                required
                                label='ФИО'
                                name='fullName'
                                rules={[{ required: true, message: 'Укажите ФИО' }]}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    onChange={(data) => {
                                        setEntity({ ...entity, fullName: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.phone}
                                required
                                name='phone'
                                label='Телефон'
                                rules={[{ required: true, message: 'Укажите телефон' }]}
                            >
                                <MaskedInput
                                    disabled={isViewOnly}
                                    size='middle'
                                    mask={'+0 (000) 000-00-00'}
                                    onChange={(data) => {
                                        setPhone(data.maskedValue);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.email}
                                validateFirst={true}
                                label='Email'
                                name='email'
                                rules={[
                                    { type: 'email', message: 'Неверный формат Email' },
                                    {
                                        validator: async (_, value) => {
                                            let isEmailExist = await serverFetch(`users/emailexist/${userId}/${value}`, {
                                                method: 'GET',
                                            })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки Email', ex, () => d(userLoaded(undefined)));
                                                });

                                            if (!userId && isEmailExist)
                                                return Promise.reject('Учетная запись с указанным email уже существует');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    autoComplete='none'
                                    onChange={(data) => {
                                        setEntity({ ...entity, email: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Активно' name='isActive' valuePropName='checked'>
                                <Switch
                                    disabled={isViewOnly}
                                    defaultChecked={entity?.isActive}
                                    onChange={(value) => {
                                        setEntity({ ...entity, isActive: value });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Space size={'small'} style={{ float: 'right' }}>
                                    <Button type='text' onClick={() => navigate(-1)}>
                                        Отменить
                                    </Button>
                                    {!isViewOnly &&
                                        (entity.isArchived ? (
                                            hasPermission(userSession.permissions, Permission.FullAccess) && (
                                                <Button type='primary' onClick={() => onRestore()}>
                                                    Восстановить
                                                </Button>
                                            )
                                        ) : (
                                            <Button type='primary' htmlType='submit' loading={loading}>
                                                Сохранить
                                            </Button>
                                        ))}
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={15} offset={1}>
                            {entity.type === UserType.System ? renderPermissions() : renderSettings()}
                        </Col>
                    </Row>
                </Form>
            )
        );
    };

    let tabItems = [
        {
            label: `Основное`,
            key: 'main',
            children: renderUserForm(),
        },
    ];

    if (userId && (entity?.type === UserType.MainClient || entity?.type === UserType.Supplier)) {
        tabItems.push({
            label: `Финансы`,
            key: 'finance',
            children: (
                <Row>
                    <Col span={12}>
                        {renderAccounts()}
                        <Discounts userId={userId} userDebts={userDebts} isViewOnly={isViewOnly} />
                    </Col>
                    <Col offset={1} span={11}>
                        <Debts
                            userId={userId}
                            userDebts={userDebts}
                            isViewOnly={isViewOnly}
                            onRefresh={() => setUserDebtsRefreshRequired(true)}
                        />
                    </Col>
                </Row>
            ),
        });

        tabItems.push({
            label: `Контрагенты`,
            key: 'companies',
            children: <Companies userId={userId} isViewOnly={isViewOnly} />,
        });

        tabItems.push({
            label: `Сотрудники`,
            key: 'employees',
            children: <Employees userId={userId} userLogin={entity.login} isViewOnly={isViewOnly} />,
        });
    }

    return (
        <>
            <FormHeader
                title={`${userId ? (isViewOnly ? 'Информация о пользователе' : 'Изменить пользователя') : 'Добавить пользователя'}`}
            />
            <Tabs size='large' items={tabItems} />

            {contextHolder}
        </>
    );
};

export default User;
